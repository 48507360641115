import { Button, Col, Descriptions, Divider, Empty, Row, Select, Space, Spin, Table, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDepositAll, getDepositByBank, getDepositYesterday, getServerOnProfile, getServers } from '../../service/services';
import { disconnectSocket, initiateSocketConnection } from '../../service/socketio.service';
import './withdrawOverview.css';
import { Buffer } from 'buffer';
import Notification from '../../components/Notification';
import { numberWithCommas } from '../../utils/utils';

const { Title } = Typography;

const renderColumns = (serverType) => {
    const columns = [
        {
            title: <b>UID</b>,
            dataIndex: '_id',
            key: '_id',
            width: 150
        },
        {
            title: <b>วันที่</b>,
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            // render: (text, record, index) => {
            //     const textSplit = text.split("/");
            //     return serverType.type === 'multi' ? (textSplit[0] + "/" + textSplit[1]) : text;
            // }
        },
        {
            title: <b>เวลา</b>,
            dataIndex: 'time',
            key: 'time',
            align: 'center'
        },
        {
            title: <b>จำนวน</b>,
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            render: (text, record, index) => {
                return numberWithCommas(text);
            }
        },
        // {
        //     title: <b>เช็ค</b>,
        //     dataIndex: 'check',
        //     key: 'check',
        //     align: 'center',
        //     render: (text, record, index) => {
        //         return text ? record.checker_name + 'เช็ค' : 'ยังไม่เช็ค';
        //     }
        // },
    ];

    return columns;
}

const myWithdrawServer = [
    {
        id: 1,
        name: "สิทธิพล",
        ip: "http://188.166.208.197:5001",
        bank: "KBank"
    },
    {
        id: 2,
        name: "ณัฐพงษ์",
        ip: "http://188.166.208.197:5002",
        bank: "KBank"
    }
]

const WithdrawOverview = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [servers, setServers] = useState(myWithdrawServer);
    const [serverSeleted, setServerSelected] = useState(null);
    const [defaultServer, setDefaultServer] = useState(null);
    const [sms, setSms] = useState([]);
    const [totalSms, setTotalSms] = useState(null);
    const [totalSmsByBank, setTotalSmsByBank] = useState(null);
    const [typeOfDeposit, setTypeOfDeposit] = useState('today');
    const [profile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [role] = useState(profile ? Buffer.from(profile.role.substring(1, profile.role.length - 2), 'base64').toString('utf8') : "");
    const [serverType, setServerType] = useState(null);
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        disconnectSocket();
    }, []);

    const onServerChange = (e) => {
        setShowResult(false);
        const myServerIndex = servers.findIndex(server => server.id === e);
        setDefaultServer(servers[myServerIndex]);
        setServerSelected(servers[myServerIndex].ip);
        if (servers[myServerIndex].name.indexOf('(') !== -1) {
            setServerType({
                type: 'once',
                bank: servers[myServerIndex].bank
            });
        } else {
            setServerType({
                type: 'multi',
                bank: 'all'
            });
        }
    }

    const connectServer = async () => {
        setLoading(true);
        if (!serverSeleted) {
            Notification('กรุณาเลือกบัญชี', 'error');
            return;
        }
        const url = serverSeleted + "/";
        // const url = "http://localhost/";
        try {
            disconnectSocket();
            const socket = initiateSocketConnection(url);
            setTypeOfDeposit('now');

            socket.on('smsSent', async () => {
                let response = null;
                if (defaultServer.name.indexOf('(') !== -1) {
                    response = await getDepositByBank(url, defaultServer.bank, 'เงินโอน');
                } else {
                    response = await getDepositAll(url, 'เงินโอน');
                }

                if (response.status === 200) {
                    setTotalSms(response.data.totalSms);
                    setTotalSmsByBank(!response.data.totalSmsByBank ? null : response.data.totalSmsByBank);
                    setSms(response.data.sms);
                } else {
                    setTotalSms(null);
                    setTotalSmsByBank(null);
                    setSms(null);
                }
            });

            socket.on('smsChecked', async (smsChecked) => {
                const index = sms.findIndex(v => v._id.toString() === smsChecked._id.toString());
                if (index !== -1) {
                    if (sms[index].check !== smsChecked.check) {
                        let response = null;
                        if (defaultServer.name.indexOf('(') !== -1) {
                            response = await getDepositByBank(url, defaultServer.bank, 'เงินโอน');
                        } else {
                            response = await getDepositAll(url, 'เงินโอน');
                        }

                        if (response.status === 200) {
                            setTotalSms(response.data.totalSms);
                            setTotalSmsByBank(!response.data.totalSmsByBank ? null : response.data.totalSmsByBank);
                            setSms(response.data.sms);
                        } else {
                            setTotalSms(null);
                            setTotalSmsByBank(null);
                            setSms(null);
                        }
                    }
                }

            });

            let response = null;
            if (defaultServer.name.indexOf('(') !== -1) {
                response = await getDepositByBank(url, defaultServer.bank, 'เงินโอน');
            } else {
                response = await getDepositAll(url, 'เงินโอน');
            }

            if (response.status === 200) {
                setTotalSms(response.data.totalSms);
                setTotalSmsByBank(!response.data.totalSmsByBank ? null : response.data.totalSmsByBank);
                setSms(response.data.sms);
            } else {
                setTotalSms(null);
                setTotalSmsByBank(null);
                setSms([]);
            }
            setShowResult(true);
            setLoading(false);
        } catch (error) {
            setTotalSms(null);
            setTotalSmsByBank(null);
            setSms([]);
            setShowResult(false);
            setLoading(false);
        }
    }

    const depositYesterday = async () => {
        disconnectSocket();
        setLoading(true);
        const url = serverSeleted + "/";
        try {
            const response = await getDepositYesterday(url, defaultServer.bank, 'เงินโอน');
            if (response.status === 200) {
                setTotalSms(response.data.totalSms);
                setTotalSmsByBank(!response.data.totalSmsByBank ? null : response.data.totalSmsByBank);
                setSms(response.data.sms);
            } else {
                setTotalSms(null);
                setTotalSmsByBank(null);
                setSms(null);
            }
            setTypeOfDeposit('yesterday');
            setShowResult(true);
            setLoading(false);
        } catch (error) {
            setTotalSms(null);
            setTotalSmsByBank(null);
            setSms(null);
            setShowResult(false);
            setLoading(false);
        }
    }

    const rowSetup = (record, index) => {
        if (record.check) {
            return 'checked-match';
        }
        return '';
    }

    const getSmsByBank = (bank) => {
        if (!sms) return [];
        const newSms = sms.filter(v => {
            return v.sms_address === bank
        });
        return newSms;
    }

    const showData = () => {
        // if (!showResult) {
        //     return <Empty style={{ marginTop: 20 }} description="กดดูยอด" />;
        // }

        return <>{
            serverType ? serverType.type === 'multi' ?
                <>
                    <Row gutter={[4, 4]} style={{ textAlign: 'center' }}>
                        <Col style={{ width: '100%' }}>
                            <Table bordered className="KBank" locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} size="small" columns={renderColumns(serverType)} rowClassName={rowSetup} dataSource={getSmsByBank('KBank')} rowKey="_id" pagination={{ defaultPageSize: 20, position: ['bottomRight'] }} />
                        </Col>
                    </Row>
                </> :
                <Table className={defaultServer.bank} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} size="small" columns={renderColumns(serverType)} rowClassName={rowSetup} dataSource={sms} rowKey="_id" pagination={{ defaultPageSize: 20, position: ['bottomRight'] }} />
                : <><Empty style={{ marginTop: 20 }} description="กรุณาเลือกบัญชี" /></>
        }</>

    }

    return <>
        <Spin spinning={loading}>
            <Title level={4}>หน้าเงินโอนออก</Title>
            <Space>
                {servers ?
                    <Select style={{ width: '150px' }} placeholder="เลือกเครื่อง" onChange={(e) => onServerChange(e)}>
                        {servers.map(v => <Select.Option value={v.id}>{v.name}</Select.Option>)}
                    </Select> : <></>}
                <Button type="primary" onClick={() => connectServer()} disabled={defaultServer ? false : true}>ดูยอดวันนี้</Button>
                {serverSeleted ? <Button type="primary" onClick={() => depositYesterday()}>ดูยอดเมื่อวาน</Button> : <></>}
            </Space>
            <Divider style={{ margin: '10px 0' }} />
            {(showResult && role === 'admin' && totalSms) ? <Descriptions column={4}>
                <Descriptions.Item label={<b>บัญชี</b>}>{defaultServer.name}</Descriptions.Item>
                <Descriptions.Item label={<b>ประเภท</b>}>{typeOfDeposit === 'now' ? 'วันนี้' : 'เมื่อวานนี้'}</Descriptions.Item>
                <Descriptions.Item label={<b>ยอดเงินโอนออก</b>}>{totalSms.totalTransaction.replace("ยอดเงินวันนี้ ", "")}</Descriptions.Item>
                <Descriptions.Item label={<b>รายการทั้งหมด</b>}>{totalSms.transaction.replace("ออเดอร์ ", "")}</Descriptions.Item>
            </Descriptions> : <></>}
            {showData()}
        </Spin>
    </>
}


export default WithdrawOverview;