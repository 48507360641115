import React, { Component } from 'react';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Empty, Image, Modal, Select, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import Notification from '../../components/Notification';
import './slipImage.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { deleteAllImages, deleteImageById, getImages, getServerOnProfile, getServers } from '../../service/services';
import { disconnectAllSocket } from '../../service/socketio.service';

const { RangePicker } = DatePicker;

const SlipImage = () => {
    let history = useHistory();
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverList, setServerList] = useState([]);
    const [server, setServer] = useState('all');
    const [serverId, setServerId] = useState('all');
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD 00:00:00"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD 23:59:59"));

    const getImagesByTime = async (startDate, endDate, serverId) => {
        let query = "?";
        query += "filter=createdAt||$gte||" + startDate + "&filter=createdAt||$lte||" + endDate;

        if (serverId === 'all') {
            // console.log(serverList);
            const ids = serverList.map(v => v.id);
            if (ids.length !== 0) query += "&filter=server.id||$in||" + ids.join(",");
            // console.log(ids);
        } else {
            query += "&filter=server.id||$eq||" + serverId;
        }

        query += "&filter=member.id||$eq||" + JSON.parse(localStorage.getItem('profile')).id;

        query += "&sort=id,DESC";
        const data = await getImages(query);
        return data;
    }

    useEffect(() => {
        disconnectAllSocket();
        if (serverList.length === 0) {
            try {
                if (!JSON.parse(localStorage.getItem('profile')).servers) {
                    getServers().then(res => {
                        if (res.status === 200) setServerList(res.data.data);
                        else setServerList([]);
                    }).catch(reason => setServerList([]));
                } else {
                    const servers = getServerOnProfile();
                    setServerList(servers);
                }
            } catch (error) {
                history.push('/signin');
            }
        }

        const response = getImagesByTime(startDate, endDate, 'all');
        response.then(res => {
            if (res.status === 200) {
                setImages(res.data.data);
            } else {
                setImages([])
            }
            setLoading(false);
        }).catch(reason => {
            setImages([]);
            setLoading(false);
        });

    }, [])

    const onConfirmDeleteImage = (image) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการลบรูปภาพใช่หรือไม่?',
            okText: 'ลบ',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk: async () => onDeleteImage(image)
        });
    }

    const onDeleteImage = async (image) => {
        setLoading(true);
        const response = await deleteImageById(image.id);
        if (response.status === 200) {
            const newImagesResponse = await getImageByTime(startDate, endDate);
            if (newImagesResponse.status !== 200) {
                setImages(null);
            } else {
                setImages(newImagesResponse.data.data);
            }
        }
        setLoading(false);
    }

    const renderImages = () => {
        if (images.length === 0) return <Empty style={{ margin: 50 }} description={'ไม่มีข้อมูล'} />;
        return images.map(image => {
            const imageElement = <div key={image.id} style={{ display: 'inline-block', position: 'relative' }}>
                <Image
                    key={image.id}
                    style={{ padding: 5 }}
                    width={170}
                    height={170}
                    src={image.img}
                />
                <CloseOutlined key={image.id} style={{ position: 'absolute', top: 5, right: 5, fontSize: 30, color: 'red', opacity: 0.8 }} onMouseEnter={(e) => { e.target.style.opacity = 1 }} onMouseLeave={(e) => { e.target.style.opacity = 0.8 }} onClick={() => onConfirmDeleteImage(image)} />
            </div>;

            return imageElement;
        });
    }

    const refreshImages = async (filter) => {
        setLoading(true);
        setServerId('all');
        setStartDate(moment().format("YYYY-MM-DD 00:00:00"));
        setEndDate(moment().format("YYYY-MM-DD 23:59:59"));
        const response = await getImageByTime(startDate, endDate, 'all');

        if (response.status !== 200) {
            setImages(null);
        } else {
            setImages(response.data.data);
        }
        setLoading(false);
    }

    const getImagesByServer = async (e) => {
        setLoading(true);
        const serverId = serverList.findIndex(v => v.ip === e);
        const id = serverId === -1 ? 'all' : serverList[serverId].id;
        setServerId(id);
        setServer(e);

        const response = await getImageByTime(startDate, endDate, id);
        if (response.status !== 200) {
            setImages(null);
        } else {
            setImages(response.data.data);
        }
        setLoading(false);
    }

    const deleteAll = async () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการลบรูปภาพทั้งหมดใช่หรือไม่?',
            okText: 'ลบทั้งหมด',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk: async () => onDeleteAllImage()
        });
    }

    const onDeleteAllImage = async () => {
        setLoading(true);
        const data = {
            member: JSON.parse(localStorage.getItem('profile')).id,
        }

        if (serverId !== 'all') {
            data.server = serverId
        }

        try {
            const response = await deleteAllImages(data);
            Notification("ลบรูปภาพทั้งหมดเรียบร้อย", "success");
            refreshImages('desc');
        } catch (error) {
            Notification("มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง", "error");
            setLoading(false);
        }
    }

    const getImageByTime = async (startDate, endDate, serverId) => {
        let query = "?";
        // const startToday = moment().format("YYYY-MM-DD 00:00:00");
        // const endToday = moment().format("YYYY-MM-DD 23:59:59");
        query += "filter=createdAt||$gte||" + startDate + "&filter=createdAt||$lte||" + endDate;

        if (serverId === 'all') {
            // console.log(serverList);
            const ids = serverList.map(v => v.id);
            query += "&filter=server.id||$in||" + ids.join(",");
            // console.log(ids);
        } else {
            query += "&filter=server.id||$eq||" + serverId;
        }

        query += "&sort=id,DESC";
        const data = await getImages(query);
        return data;
    }

    const getDateByCondition = async (cond) => {
        setLoading(true);
        let startNewDate = '';
        let endNewDate = '';
        if (cond === 'today') {
            startNewDate = moment().format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        } else if (cond === 'yesterday') {
            startNewDate = moment().subtract(1, 'day').format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().subtract(1, 'day').format("YYYY-MM-DD 23:59:59");
        } else if (cond === 'weekly') {
            startNewDate = moment().subtract(6, 'day').format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        }
        else if (cond === 'between') {
            startNewDate = startDate;
            endNewDate = endDate;
        } else {
            Notification('คุณใส่วันที่ไม่ถูกต้อง กรุณาลองอีกครั้ง', 'error');
            startNewDate = moment().format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        }

        setStartDate(startNewDate);
        setEndDate(endNewDate);

        const response = await getImageByTime(startDate, endDate, serverId);
        if (response.status !== 200) {
            setImages(null);
        } else {
            setImages(response.data.data);
        }
        setLoading(false);
    }

    const onChangeDate = (e) => {
        if (e) {
            const startDate = moment(e[0]).format("YYYY-MM-DD 00:00:00");
            const endDate = moment(e[1]).format("YYYY-MM-DD 23:59:59");
            setStartDate(startDate);
            setEndDate(endDate);
        }
    }

    return (
        <>
            <Spin spinning={loading}>
                <Space>
                    {/* <Button type="default" onClick={() => refreshImages('desc')}>เรียงจากล่าสุดไปเก่า</Button>
                    <Button type="default" onClick={() => refreshImages('asc')}>เรียงจากเก่าไปล่าสุด</Button> */}
                    <Button type="default" onClick={() => getDateByCondition('today')}>วันนี้</Button>
                    <Button type="default" onClick={() => getDateByCondition('yesterday')}>เมื่อวาน</Button>
                    <Button type="default" onClick={() => getDateByCondition('weekly')}>อาทิตย์นี้</Button>
                    <RangePicker placeholder={['ตั้งแต่วันที่', "ถึงวันที่"]} onChange={(e) => onChangeDate(e)} />
                    <Button type="primary" onClick={() => getDateByCondition('between')}>ค้นหา</Button>
                    <Button type="default" onClick={() => refreshImages()} >Refresh</Button>
                    <Button type="default" danger onClick={() => deleteAll()}>ลบทั้งหมด</Button>
                </Space>
                {serverList ?
                    <Select style={{ width: '100%', marginTop: 10 }} placeholder="เลือกเครื่อง" defaultValue={serverId} onChange={(e) => getImagesByServer(e)}>
                        <Select.Option value={'all'}>{'รูปภาพทั้งหมด'}</Select.Option>
                        {serverList.map(v => <Select.Option value={v.ip}>{v.name}</Select.Option>)}
                    </Select> : <></>}
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    {renderImages()}
                </div>
            </Spin>

        </>
    )
}


export default SlipImage;