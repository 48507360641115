import React, { useEffect, useState } from "react";
import { Buffer } from 'buffer';
import { Button, Col, Descriptions, Divider, Empty, Modal, Result, Row, Space, Spin, Table, Tag, Typography } from "antd";
import { useHistory } from "react-router-dom";
import './deposit-2-import.css';
import { getDeposit, getDepositYesterday, getServerOnProfile, getServers } from "../../service/services";
import { containArray, numberWithCommas } from "../../utils/utils";
import { disconnectAllSocket, disconnectSocketSittiphon, disconnectSocketSuchada, initiateSocketSittiphon, initiateSocketSuchada } from "../../service/socketio.service";

const { Title } = Typography;
const config = [{ name: "Hattapol", ip: "http://68.183.191.144" }, { name: "Kwanjai", ip: "http://134.209.104.110" }];
const configNameOnly = ["Hattapol", "Kwanjai"];

const renderColumns = () => {
    const columns = [
        {
            title: <b>วันที่</b>,
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: <b>เวลา</b>,
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: <b>โอนจาก</b>,
            dataIndex: 'account_from',
            key: 'account_from'
        },
        {
            title: <b>จำนวนเงิน</b>,
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: <b>เช็ค</b>,
            dataIndex: 'check',
            key: 'check',
            render: (text, record, index) => {
                if (text) return record.checker_name === 'MySMS' ? record.checker_name + ' เช็ค' : record.checker_name + 'เช็ค';
                return 'ยังไม่เช็ค';
            }
        },
    ];

    return columns;
}

const rowSetup = (record, index) => {
    if (record.check) {
        return 'checked-match';
    }
    return '';
}

const Deposit2Import = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [smsSittiphon, setSmsSittiphon] = useState([]);
    const [totalSmsSittiphon, setTotalSmsSittiphon] = useState(null);
    const [smsSuchada, setSmsSuchada] = useState([]);
    const [totalSmsSuchada, setTotalSmsSuchada] = useState(null);
    const [typeOfDeposit, setTypeOfDeposit] = useState('now');
    const [bank] = useState('KBank');
    const [canAccess, setCanAccess] = useState(false);
    const [servers, setServers] = useState([]);
    const [profile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [role] = useState(profile ? Buffer.from(profile.role.substring(1, profile.role.length - 2), 'base64').toString('utf8') : "");

    useEffect(() => {
        disconnectAllSocket();
        if (servers.length === 0) {
            try {
                if (!JSON.parse(localStorage.getItem('profile')).servers) {
                    getServers().then(res => {
                        if (res.status === 200) {
                            const serversNameOnly = res.data.data.map(v => v.name);
                            const isCan = containArray(configNameOnly, serversNameOnly);
                            setCanAccess(isCan);
                            setServers(res.data.data);
                        } else setServers([]);
                    }).catch(reason => setServers([]));
                } else {
                    const servers = getServerOnProfile();
                    const serversNameOnly = servers.map(v => v.name);
                    const isCan = containArray(configNameOnly, serversNameOnly);
                    setCanAccess(isCan);
                    setServers(servers);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                history.push('/signin');
            }
        }

        if (smsSittiphon) {
            if (smsSittiphon.length === 0) connectServerSittiphon();
        }

        if (smsSuchada) {
            if (smsSuchada.length === 0) connectServerSuchada();
        }

        // if (smsSittiphon.length !== 0) {
        //     console.log("SITTIPHON SOCKET");
        newSmsSittiphonRelease();
        // }

        // if (smsSuchada.length !== 0) {
        // console.log("SITTIPHON SOCKET");
        newSmsSuchadaRelease();
        // }

        return () => {
            disconnectSocketSittiphon();
            disconnectSocketSuchada();
        }

    }, [smsSittiphon, smsSuchada]);

    const newSmsSittiphonRelease = () => {
        disconnectSocketSittiphon();
        const socketSittiphon = initiateSocketSittiphon(config[1].ip);
        socketSittiphon.on('smsSent', async (newSms) => {
            try {
                const dataTotalTransactionSplit = totalSmsSittiphon.totalTransaction.replace(/,/g, "").split(" ");
                let sumTotalTransaction = parseFloat(dataTotalTransactionSplit[1]);
                let count = 0;
                const filterSms = [];
                newSms.forEach(v => {
                    if (v.type === 'เงินฝาก') {
                        filterSms.push(v);
                        sumTotalTransaction += v.amount;
                        count++;
                    }
                })
                sumTotalTransaction = numberWithCommas(sumTotalTransaction);

                const dataTransactionSplit = totalSmsSittiphon.transaction.replace(",", "").split(" ");
                let sumTransaction = parseFloat(dataTransactionSplit[1]) + count;
                sumTransaction = numberWithCommas(sumTransaction);

                const newTotalSms = {
                    totalTransaction: dataTotalTransactionSplit[0] + " " + sumTotalTransaction + " " + dataTotalTransactionSplit[2],
                    transaction: dataTransactionSplit[0] + " " + sumTransaction + " " + dataTransactionSplit[2]
                }

                setTotalSmsSittiphon(newTotalSms);
                setSmsSittiphon(filterSms.concat(smsSittiphon));
            } catch (error) {
                setTotalSmsSittiphon(null);
                setSmsSittiphon(null);
            }

        });

        socketSittiphon.on('smsChecked', async (smsChecked) => {
            const index = smsSittiphon ? smsSittiphon.findIndex(v => v._id.toString() === smsChecked._id.toString()) : -1;
            if (index !== -1) {
                const newSms = [...smsSittiphon];
                newSms[index] = smsChecked;
                setSmsSittiphon(newSms);
            }
        });
    }

    const newSmsSuchadaRelease = () => {
        disconnectSocketSuchada();
        const socketSuchada = initiateSocketSuchada(config[0].ip);

        socketSuchada.on('smsSent', async (newSms) => {
            try {
                const dataTotalTransactionSplit = totalSmsSuchada.totalTransaction.replace(/,/g, "").split(" ");
                let sumTotalTransaction = parseFloat(dataTotalTransactionSplit[1]);
                let count = 0;
                const filterSms = [];
                newSms.forEach(v => {
                    if (v.type === 'เงินฝาก') {
                        filterSms.push(v);
                        sumTotalTransaction += v.amount;
                        count++;
                    }
                })
                sumTotalTransaction = numberWithCommas(sumTotalTransaction);

                const dataTransactionSplit = totalSmsSuchada.transaction.replace(",", "").split(" ");
                let sumTransaction = parseFloat(dataTransactionSplit[1]) + count;
                sumTransaction = numberWithCommas(sumTransaction);

                const newTotalSms = {
                    totalTransaction: dataTotalTransactionSplit[0] + " " + sumTotalTransaction + " " + dataTotalTransactionSplit[2],
                    transaction: dataTransactionSplit[0] + " " + sumTransaction + " " + dataTransactionSplit[2]
                }

                setTotalSmsSuchada(newTotalSms);
                setSmsSuchada(filterSms.concat(smsSuchada));
            } catch (error) {
                setTotalSmsSuchada(null);
                setSmsSuchada(null);
            }

        });

        socketSuchada.on('smsChecked', async (smsChecked) => {
            const index = smsSuchada ? smsSuchada.findIndex(v => v._id.toString() === smsChecked._id.toString()) : -1;
            if (index !== -1) {
                const newSms = [...smsSuchada];
                newSms[index] = smsChecked;
                setSmsSuchada(newSms);
            }
        });
    }

    const connectServerSuchada = async () => {
        setLoading(true);
        try {
            // Suchada
            const url = config[0].ip + ":5000/";
            const response = await getDeposit(url, bank);
            if (response.status === 200) {
                setTotalSmsSuchada(response.data.totalSms);
                setSmsSuchada(response.data.sms);
            } else {
                setTotalSmsSuchada(null);
                setSmsSuchada(null);
            }

            setLoading(false);
        } catch (error) {
            setTotalSmsSuchada(null);
            setSmsSuchada(null);
            setLoading(false);
        }
    }

    const connectServerSittiphon = async () => {
        setLoading(true);
        try {
            // Sittiphon
            const url = config[1].ip + ":5000/";
            const response = await getDeposit(url, bank);
            if (response.status === 200) {
                setTotalSmsSittiphon(response.data.totalSms);
                setSmsSittiphon(response.data.sms);
            } else {
                setTotalSmsSittiphon(null);
                setSmsSittiphon(null);
            }

            setLoading(false);
        } catch (error) {
            setTotalSmsSittiphon(null);
            setSmsSittiphon(null);
            setLoading(false);
        }
    }

    const depositYesterdaySittiphon = async () => {
        disconnectSocketSittiphon();
        setLoading(true);
        const url = config[1].ip + ":5000/";
        try {
            const response = await getDepositYesterday(url, bank);
            if (response.status === 200) {
                setTotalSmsSittiphon(response.data.totalSms);
                setSmsSittiphon(response.data.sms);
            } else {
                setTotalSmsSittiphon(null);
                setSmsSittiphon(null);
            }
            setTypeOfDeposit('yesterday');
        } catch (error) {
            setTotalSmsSittiphon(null);
            setSmsSittiphon(null);
        }

        setLoading(false);
    }

    const depositYesterdaySuchada = async () => {
        disconnectSocketSuchada();
        setLoading(true);
        const url = config[0].ip + ":5000/";
        try {
            const response = await getDepositYesterday(url, bank);
            if (response.status === 200) {
                setTotalSmsSuchada(response.data.totalSms);
                setSmsSuchada(response.data.sms);
            } else {
                setTotalSmsSuchada(null);
                setSmsSuchada(null);
            }
            setTypeOfDeposit('yesterday');
        } catch (error) {
            setTotalSmsSuchada(null);
            setSmsSuchada(null);
        }

        setLoading(false);
    }

    const showData = () => {
        return (
            <>
                <Title level={4}>หน้าเงินฝาก</Title>
                <Row gutter={16} style={{ textAlign: 'center' }}>
                    <Col span={12}>
                        <Title level={5}><Tag color={'#00a950'} style={{ fontSize: 16 }}>{configNameOnly[1]}</Tag></Title>
                        <Space>
                            <Button type="primary" size="small" onClick={() => connectServerSittiphon()}>ดูยอดวันนี้</Button>
                            <Button type="primary" size="small" onClick={() => depositYesterdaySittiphon()}>ดูยอดเมื่อวาน</Button>
                        </Space>
                        <Divider style={{ margin: '5px' }} />
                        {role !== 'member' ?
                            <Descriptions column={2}>
                                <Descriptions.Item style={{ paddingBottom: 4 }} label={<b>ยอดเงิน</b>}>{totalSmsSittiphon ? totalSmsSittiphon.totalTransaction.split(" ")[1] : 0}</Descriptions.Item>
                                <Descriptions.Item style={{ paddingBottom: 4 }} label={<b>รายการ</b>}>{totalSmsSittiphon ? totalSmsSittiphon.transaction.split(" ")[1] : 0}</Descriptions.Item>
                            </Descriptions> : <></>}
                        <Table locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} size="small" columns={renderColumns()} rowClassName={rowSetup} dataSource={smsSittiphon} rowKey="_id" pagination={{ defaultPageSize: 20, position: ['bottomRight'] }} />;
                    </Col>
                    <Col span={12}>
                        <Title level={5}><Tag color={'#00a950'} style={{ fontSize: 16 }}>{configNameOnly[0]}</Tag></Title>
                        <Space>
                            <Button type="primary" size="small" onClick={() => connectServerSuchada()}>ดูยอดวันนี้</Button>
                            <Button type="primary" size="small" onClick={() => depositYesterdaySuchada()}>ดูยอดเมื่อวาน</Button>
                        </Space>
                        <Divider style={{ margin: '5px' }} />
                        {role !== 'member' ?
                            <Descriptions column={2}>
                                <Descriptions.Item style={{ paddingBottom: 4 }} label={<b>ยอดเงิน</b>}>{totalSmsSuchada ? totalSmsSuchada.totalTransaction.split(" ")[1] : 0}</Descriptions.Item>
                                <Descriptions.Item style={{ paddingBottom: 4 }} label={<b>รายการ</b>}>{totalSmsSuchada ? totalSmsSuchada.transaction.split(" ")[1] : 0}</Descriptions.Item>
                            </Descriptions> : <></>}
                        <Table locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} size="small" columns={renderColumns()} rowClassName={rowSetup} dataSource={smsSuchada} rowKey="_id" pagination={{ defaultPageSize: 20, position: ['bottomRight'] }} />;
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            <Spin spinning={loading}>
                {canAccess ?
                    showData() :
                    <Result
                        status="warning"
                        title="ไม่มีสิทธิ์เข้าใช้งาน"
                    // extra={
                    //     <Button type="primary" key="console" onClick={() => history.push('/deposit')}>
                    //         กลับหน้าเงินฝาก
                    //     </Button>
                    // }
                    />
                }
            </Spin>
        </>
    )
}

export default Deposit2Import;