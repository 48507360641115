import { connect } from 'socket.io-client'
let socket;
let socketSittiphon;
let socketSuchada;

export const initiateSocketConnection = (endpoint) => {
    socket = connect(endpoint);
    return socket;
}

export const disconnectSocket = () => {
    if (socket) socket.disconnect();
}

export const disconnectAllSocket = () => {
    if (socket) socket.disconnect();
    if (socketSittiphon) socketSittiphon.disconnect();
    if (socketSuchada) socketSuchada.disconnect();
}

export const initiateSocketOnlyImportConnection = (endpointSittiphon, endpointSuchada) => {
    socketSittiphon = connect(endpointSittiphon + "/", {
        cors: {
            origin: "*",
            methods: ["GET"],
            allowedHeaders: ["*"],
            credentials: true
        }
    });
    socketSuchada = connect(endpointSuchada + "/", {
        cors: {
            origin: "*",
            methods: ["GET"],
            allowedHeaders: ["*"],
            credentials: true
        }
    });
    return {
        socketSittiphon,
        socketSuchada
    }
}

export const disconnectSocketOnlyImport = () => {
    if (socketSittiphon) socketSittiphon.disconnect();
    if (socketSuchada) socketSuchada.disconnect();
}

export const initiateSocketSittiphon = (endpoint) => {
    socketSittiphon = connect(endpoint + "/");
    return socketSittiphon;
}

export const initiateSocketSuchada = (endpoint) => {
    socketSuchada = connect(endpoint + "/");
    return socketSuchada;
}

export const disconnectSocketSittiphon = () => {
    if (socketSittiphon) socketSittiphon.disconnect();
}

export const disconnectSocketSuchada = () => {
    if (socketSuchada) socketSuchada.disconnect();
}