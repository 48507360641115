import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom'
import 'antd/dist/antd.min.css'
import './index.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Signin from './pages/Signin/Signin';

const App = () => {

  useEffect(() => {
    console.log("CLEARING CACHE...");
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/signin" component={Signin} />
      </Switch>
    </HashRouter>
  )
}

export default App;