import React, { Component } from 'react';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Spin, Table, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import Notification from "../../components/Notification";
import { deleteMember, getMembers, getSearchMembers, getServerOnProfile, getServers, saveNewMember, updateMember } from '../../service/services';
import { Buffer } from 'buffer';
import { containArray } from '../../utils/utils';
import { disconnectAllSocket } from '../../service/socketio.service';

const { Title } = Typography;
const { Option } = Select;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const memberColumns = (onEditMember, onConfirmDeleteMember, filterType) => {
    const columns = [
        {
            title: 'NO',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 70,
            render: (text, record, index) => {
                return <>{index + 1}</>
            }
        },
        {
            title: 'ชื่อเล่น',
            dataIndex: 'nickname',
            key: 'nickname',
            fixed: 'left',
            width: 100,
        },
        {
            title: 'ชื่อผู้ใช้ในระบบ',
            dataIndex: 'username',
            key: 'username',
            // width: 150,
        },
        {
            title: 'ตำแหน่ง',
            dataIndex: 'role',
            key: 'role',
            render: (text, record, index) => {
                if (text === 'admin') return <Tag color={'#108ee9'} key={text}>ผู้ดูแลระบบ</Tag>;
                else if (text === 'manager') return <Tag color={'#2db7f5'} key={text}>หัวหน้า</Tag>;
                else if (text === 'member') return <Tag color={'#f50'} key={text}>สมาชิก</Tag>;
                else if (text === 'moderator') return <Tag color={'#87d068'} key={text}>ผู้ดูแลระบบ (รอง)</Tag>;

                return <Tag color={'red'} key={text}>{text}</Tag>
            }
        },
        {
            title: 'สังกัด',
            dataIndex: 'permission',
            key: 'permission',
            align: 'center',
            render: (text, record, index) => {
                if (!text) return "ทุกเซิร์ฟเวอร์";

                try {
                    const servers = JSON.parse(text).map(v => v.name);
                    return servers.join(", ");
                } catch (error) {
                    return text.join(", ");
                }
            }
        },
        {
            title: 'จัดการ',
            key: 'action',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onEditMember(record)}>แก้ไข</Button>
                    <Button size="small" danger onClick={() => onConfirmDeleteMember(record)}>ลบ</Button>
                </Space>
            ),
        },
    ];

    if (filterType === 'search') {
        const searchColumns = columns.filter(v => {
            return v.dataIndex !== 'total';
        });

        return searchColumns;
    }
    return columns;
}


const Member = () => {
    const [form] = Form.useForm();
    const [formAdd] = Form.useForm();
    const [loadingForm, setLoadingForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editMemberVisible, setEditMemberVisible] = useState(false);
    const [addMemberVisible, setAddMemberVisible] = useState(false);
    const [deleteMemberVisible, setDeleteMemberVisible] = useState(false);
    const [members, setMembers] = useState(null);
    const [membersTemp, setMembersTemp] = useState(null);
    const [memberSelected, setMemberSelected] = useState(null);
    const [filterType, setFilterType] = useState('search');
    const [roleSelected, setRoleSelected] = useState('');
    const [nickname, setNickname] = useState('');
    const [serverList, setServerList] = useState([]);
    const [plainCheckOptions, setPlainCheckOptions] = useState([]);
    const [checkedServers, setCheckedServers] = useState([]);
    const [profile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [role] = useState(profile ? Buffer.from(profile.role.substring(1, profile.role.length - 2), 'base64').toString('utf8') : "");

    useEffect(() => {
        disconnectAllSocket();
        setAllServers();

        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMembers(null);
                setMembersTemp(null);
            } else {
                setMembers(res.data.data);
                setMembersTemp(res.data.data);
            }
            setLoading(false);
        })
    }, []);

    const onSearchMember = async () => {
        setFilterType('search');
        if (nickname === '') return;
        setLoading(true);
        const response = await getSearchMembers(nickname);

        if (response.status === 200) {
            setMembers(response.data.data);
        } else {
            Notification('Something wrong. Please try again.', 'error');
        }
        setLoading(false);
    }

    const onAddMember = async () => {
        formAdd.resetFields();
        setAddMemberVisible(true);
        setLoadingForm(true);

        if (serverList.length === 0) {
            await setAllServers();
        }

        setLoadingForm(false);
    }

    const setAllServers = async () => {
        if (role === 'admin') {
            const response = await getServers();
            if (response.status === 200) {
                setServerList(response.data.data);
                const serverNameList = response.data.data.map(v => v.name);
                setPlainCheckOptions(serverNameList);
            }
        } else {
            const servers = getServerOnProfile();
            setServerList(servers);
            const serversNameOnly = servers.map(v => v.name);
            setPlainCheckOptions(serversNameOnly);
        }

    }

    const onConfirmAdd = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSaveNewMember()
        });
    }

    const onSaveNewMember = async () => {
        setLoadingForm(true);
        formAdd.validateFields().then(async (value) => {
            const data = formAdd.getFieldValue();
            const tempPermission = [];
            if (data.permission) {
                data.permission.forEach(v => {
                    const index = serverList.findIndex(server => server.name === v);
                    tempPermission.push(serverList[index]);
                })
            }
            data.permission = tempPermission.length !== 0 ? JSON.stringify(tempPermission) : null;
            const response = await saveNewMember(data);
            if (response.status === 201) {
                const responseMembers = await getMembers();
                if (responseMembers.status === 200) {
                    setMembers(responseMembers.data.data);
                    setMembersTemp(responseMembers.data.data);
                } else {
                    setMembers(null);
                    setMembersTemp(null);
                }
                Notification('Save data success', 'success');
            } else {
                Notification('Can not save data. Please try again.', 'error');
            }
            setAddMemberVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            // console.log(reason);
            Notification('Can not save data. Please try again.', 'error');
            setLoadingForm(false);
        });
    }

    const onReset = () => {
        setLoading(true);
        setNickname('');
        setRoleSelected('');
        setFilterType('search');
        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMembers(null);
                setMembersTemp(null);
            } else {
                setMembers(res.data.data);
                setMembersTemp(res.data.data);
            }
            setLoading(false);
        })
    }

    const onEditMember = async (member) => {
        form.resetFields();
        if (serverList.length === 0) {
            await setAllServers();
        }
        if (member.registerPremiumDate) member.registerPremiumDate = moment(member.registerPremiumDate);
        if (member.endPremiumDate) member.endPremiumDate = moment(member.endPremiumDate);
        member.password = "";
        if (member.role !== 'admin') {
            try {
                const checked = member.permission ? JSON.parse(member.permission).map(v => v.name) : [];
                setCheckedServers(checked);
                member.permission = checked;
            } catch (error) {
                const checked = member.permission ? member.permission : [];
                setCheckedServers(checked);
                member.permission = checked;
            }
        }

        form.setFieldsValue(member);
        setRoleSelected(member.role);
        setMemberSelected(member);
        setEditMemberVisible(true);
    }

    const onConfirmSave = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSave()
        });
    }

    const onSave = () => {
        setLoadingForm(true);
        form.validateFields().then(async (value) => {
            const data = form.getFieldValue();
            const tempPermission = [];
            if (data.permission) {
                data.permission.forEach(v => {
                    const index = serverList.findIndex(server => server.name === v);
                    tempPermission.push(serverList[index]);
                })
            }
            data.permission = tempPermission.length !== 0 ? JSON.stringify(tempPermission) : null;
            const id = data.id;
            if (data.password === "") delete data.password;
            delete data.id;
            const response = await updateMember(id, data);
            if (response.status === 200) {
                const responseMembers = getMembers();
                responseMembers.then(res => {
                    if (res.status !== 200) {
                        setMembers(null);
                        setMembersTemp(null);
                    } else {
                        setMembers(res.data.data);
                        setMembersTemp(res.data.data);
                    }
                    setLoadingForm(false);
                })
                Notification('Update data success', 'success');
            } else {
                Notification('Can not update data. Please try again.', 'error');
            }
            setEditMemberVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('Can not update data. Please try again.', 'error');
            setLoadingForm(false);
        });
    }

    const onConfirmDeleteMember = (member) => {
        setMemberSelected(member);
        setDeleteMemberVisible(true);
    }

    const onDeleteMember = async () => {
        setLoadingForm(true);
        const response = await deleteMember(memberSelected.id);
        if (response.status === 200) {
            const newMembers = members.filter((member) => member.id !== memberSelected.id);
            setMembers(newMembers);
            setMembersTemp(newMembers);
            Notification('Delete member success', 'success');
        } else {
            Notification('Can not delete member. Please try again.', 'error');
        }
        setDeleteMemberVisible(false);
        setLoadingForm(false);
    }

    const onChangeRole = (e) => {
        setRoleSelected(e);
    }

    const onCheckedServer = (e) => {
        // console.log(form.getFieldValue());

        // let union = [...new Set([...e, ...checkedServers])];
        // console.log(e, union);
        // const temp = e;
        // temp.push(e);
        setCheckedServers(e);
        // console.log();
    }

    const filterData = () => {
        if (role === 'admin') return [...members];
        const allServer = serverList.map(server => server.id);
        const filterMembers = members.filter(member => {
            if (member.role !== 'admin') {
                const allPermission = JSON.parse(member.permission).map(v => v.id);
                const found = containArray(allPermission, allServer);    // allServer.some(v => allPermission.includes(v));
                if (found) return member;
            }
        });

        return [...filterMembers];
    }

    return (
        <>
            <Modal
                title={<Title level={5}>เพิ่มสมาชิก</Title>}
                style={{ top: 25 }}
                visible={addMemberVisible}
                onOk={() => onConfirmAdd()}
                onCancel={() => setAddMemberVisible(false)}
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    <Form {...layout} form={formAdd}>
                        <Form.Item
                            name="nickname"
                            label="ชื่อเล่น"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill nickname'
                                },
                            ]}
                        >
                            <Input placeholder="ระบุชื่อเล่น" />
                        </Form.Item>
                        <Form.Item
                            name="username"
                            label="ชื่อผู้ใช้"
                            val
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill username'
                                },
                            ]}
                        >
                            <Input placeholder="ระบุชื่อผู้ใช้" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="รหัสผ่าน"
                            val
                            rules={[{ required: true }]}
                        >
                            <Input type={'password'} placeholder="รหัสผ่าน" />
                        </Form.Item>
                        <Form.Item name="role" label="ตำแหน่ง" rules={[{ required: true }]}>
                            <Select
                                placeholder="เลือกตำแหน่ง"
                                onChange={(e) => onChangeRole(e)}
                            >

                                {role === 'admin' ? <Option value="admin">ผู้ดูแล</Option> : <></>}
                                <Option value="moderator">ผู้ดูแลรอง</Option>
                                <Option value="manager">หัวหน้า</Option>
                                <Option value="member">สมาชิก</Option>
                            </Select>
                        </Form.Item>
                        {roleSelected !== 'admin' ?
                            <Form.Item name="permission" label="สังกัดเซิร์ฟเวอร์" rules={[{ required: true }]}>
                                <Checkbox.Group options={plainCheckOptions}>
                                </Checkbox.Group>
                            </Form.Item>
                            : <></>}
                    </Form>
                </Spin>
            </Modal>
            <Modal
                title={<Title level={5}>แก้ไขข้อมูลสมาชิก</Title>}
                style={{ top: 25 }}
                visible={editMemberVisible}
                onOk={() => onConfirmSave()}
                onCancel={() => setEditMemberVisible(false)}
                width={1000}
            >
                {memberSelected ?
                    <Spin spinning={loadingForm}>
                        <Form {...layout} form={form}>
                            <Form.Item
                                name="nickname"
                                label="ชื่อเล่น"
                                val
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill nickname'
                                    },
                                ]}
                            >
                                <Input placeholder="ระบุชื่อเล่น" />
                            </Form.Item>
                            <Form.Item
                                name="username"
                                label="ชื่อผู้ใช้"
                                val
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill username'
                                    },
                                ]}
                            >
                                <Input placeholder="ระบุชื่อผู้ใช้" />
                            </Form.Item>
                            <Form.Item name="role" label="ตำแหน่ง" rules={[{ required: true }]}>
                                <Select
                                    placeholder="เลือกตำแหน่ง"
                                    onChange={(e) => onChangeRole(e)}
                                >
                                    {role === 'addmin' ? <Option value="admin">ผู้ดูแล</Option> : <></>}
                                    <Option value="moderator">ผู้ดูแลรอง</Option>
                                    <Option value="manager">หัวหน้า</Option>
                                    <Option value="member">สมาชิก</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="รหัสผ่าน"
                                val
                            >
                                <Input type={'password'} placeholder="กรอกรหัสผ่านเมื่อต้องการเปลี่ยน" />
                            </Form.Item>
                            {roleSelected !== 'admin' ?
                                <Form.Item name="permission" label="สังกัดเซิร์ฟเวอร์" rules={[{ required: true }]}>
                                    <Checkbox.Group options={plainCheckOptions} value={checkedServers} onChange={onCheckedServer}>
                                    </Checkbox.Group>
                                </Form.Item>
                                : <></>}
                        </Form>
                    </Spin>
                    : <></>}
            </Modal>
            <Modal
                title={<Title level={5}>ลบสมาชิก</Title>}
                style={{ top: 25 }}
                visible={deleteMemberVisible}
                onOk={() => onDeleteMember()}
                onCancel={() => setDeleteMemberVisible(false)}
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    <Title level={5}>ต้องการลบ {memberSelected ? memberSelected.nickname : ''} ใช่หรือไม่?</Title>
                </Spin>
            </Modal>
            <Spin spinning={loading}>
                <div>
                    <Title level={4}>จัดการสมาชิก</Title>
                </div>
                <Row gutter={[8, 8]}>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={6} lg={6}>
                        <div>
                            <Space>
                                <Input value={nickname} placeholder="ชื่อเล่น" onChange={(e) => setNickname(e.target.value)} />
                                {/* <Select placeholder="ตำแหน่ง">
                                    <Option value={"admin"}>ผู้ดูแล</Option>
                                    <Option value={"member"}>สมาชิก</Option>
                                </Select> */}
                            </Space>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={32} xs={32} sm={32} md={6} lg={6}>
                        <div>
                            <Space>
                                <Button type="primary" onClick={() => onSearchMember()}>ค้นหา</Button>
                                <Button type="default" onClick={() => onReset()}>Reset</Button>
                                <Button type="default" onClick={() => onAddMember(true)}>เพิ่มสมาชิก</Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
                {members ?
                    <Table style={{ marginTop: 10 }} size="small" columns={memberColumns(onEditMember, onConfirmDeleteMember, filterType)} dataSource={filterData()} scroll={{ x: 1100 }} pagination={{ defaultPageSize: 50, position: ['bottomRight'] }} /> : <></>}
            </Spin>
        </>
    )
}

export default Member;